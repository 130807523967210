@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&family=Fredoka:wght@300..700&display=swap');

html:has(.dashboard-1) {
  font-size: 12px;
  /* 1rem = 16px */
}

body:has(.dashboard-1) {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-family: Fredoka, sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.dashboard-1.app-container {
  font-weight: 100;
  display: grid !important;
  min-height: 100vh;
  max-height: 100vh;
  grid-template-rows: 1fr 3fr 1fr;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas:
    'header header header'
    'side-left main side-right'
    'footer footer footer';
  background-image: linear-gradient(to bottom right, #083663, #f8f9fa);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;

}


.dashboard-1 main .border-list,
.dashboard-1 main .list-container,
.dashboard-1 main .list-container-comments {
  border-radius: 0%;
  padding-top: 5px;
  /* height: 49%; */
  margin-bottom: 2%;
  direction: rtl;
}


.dashboard-1 main .list-container {
  height: 60%;
}

.dashboard-1 main .messages {
  height: 36%;
}

.dashboard-1 main .messages  li {
  display: flex
;
  text-align: center;
  font-weight: bolder;
  font-size: 1.5rem;
  font-weight: 500;
  align-items: baseline;
  justify-content: center;
}


.dashboard-1 main .border-list {
  height: 100%;
}

.dashboard-1 .title-2 {
  color: #343a40;
  font-weight: 700;
  text-shadow: #b18326c9 1px 0 10px;
  font-size: 2.2rem;
  margin: 1rem;
}

.dashboard-1 .big-text {
  font-size: 1.4rem !important;
  transform: scaleX(0.9);
  transform-origin: right center;
  transition: transform 0.3s ease;
}


.dashboard-1 header {
  grid-area: header;
  height: 20vh;
  overflow: hidden;
}

.dashboard-1 .left {
  grid-area: side-left;
  min-width: 30vw;

}

.dashboard-1 .right {
  grid-area: side-right;
  min-width: 30vw;
}

.dashboard-1 main {
  grid-area: main;
  height: 80vh;
}

.dashboard-1 .left,
.dashboard-1 main,
.dashboard-1 .right {
  height: 75vh;
}

.dashboard-1 .list-container,
.dashboard-1 .list-container-comments {
  background-image: url(../.../../../../src/assets/bac8.jpeg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  width: 80%;
  margin: auto;
  border-radius: 160px 160px 0 0;
  padding: 9px;
  height: 100%;
}

.dashboard-1 .list-group-item:first-child {
  margin-top: 10px;

}

.dashboard-1 .border-list {
  border-radius: 160px 160px 0 0;
  padding: 10px;
  padding-top: 30px;
  border: 3px solid #b18326;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  height: inherit;
  overflow: hidden;
}

.dashboard-1 .list-group {
  --bs-list-group-bg: none !important;
  --bs-list-group-border-color: none !important;
  direction: rtl !important;
  padding: 0;
  margin: 0;
  /* margin-top: 20%; */

}

.dashboard-1 li {
  color: black;
}

.dashboard-1 .awssld {
  height: 50vh;
}

.dashboard-1 .awssld__bullets {
  z-index: 1000000000000000;
}

.dashboard-1 .awssld__content {
  background-color: rgba(255, 255, 255, 0.15) !important;
  overflow: hidden !important;
  display: block !important;
}

.dashboard-1 .awssld__bullets button {
  width: 0.7rem !important;
  height: 0.7rem !important;
}

.dashboard-1 .list-group.scroll {
  animation: animateContainer 10s linear infinite;
}

.dashboard-1 .right-top {
  text-align: right;
}

.dashboard-1 .left-top {
  text-align: left;
}

.dashboard-1 .list-group-item {
  padding: 4px;
}

@keyframes animateContainer {
  0% {
    transform: translateY(100%);
    /* Start at the bottom */
  }

  100% {
    transform: translateY(-100%);
    /* Scroll to the top, covering both lists */
  }
}

.dashboard-1 .d-flex {
  display: flex !important;
  justify-content: space-between;
  font-weight: bolder;

}

.dashboard-1 .d-flex strong {
  text-align: right;
  font-weight: 500;
  font-size: 1.9rem;
}

.dashboard-1 .d-flex span {
  text-align: left;
  font-weight: 500;
  font-size: 1.9rem;
}

.dashboard-1 h5 {
  font-size: 1.8rem;
}

.dashboard-1 li span {
  font-weight: 500;
  font-weight: bolder;
}

.dashboard-1 li {
  text-align: justify;
  font-weight: bolder;
  font-size: 1.5rem;
  font-weight: 500;
}

.dashboard-1 .date-container,
.dashboard-1 .daily-page-container,
.dashboard-1 .clock-container {
  color: white;
  text-align: center;
  border-radius: 10px;
  margin: auto;
}

.dashboard-1 .clock-container {
  font-size: 3.2rem;
  font-weight: 700;
}

.dashboard-1 .clock-face {
  font-size: 2.5rem;
  font-weight: 500;

}

.dashboard-1 .sinagog-name {
  color: white;
  text-shadow: #b1835f 5px 0 6px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin: auto;
  padding: 15px;
  border-radius: 70px 0 70px 0;
  margin: 5px auto;

}

.dashboard-1 footer {
  grid-area: footer;
  max-height: 5vh;
  overflow: hidden;
  color: white;
}

.dashboard-1 footer strong {
  font-size: 1.2rem;
}

.dashboard-1 .awssld__timer {
  margin-top: calc(56vh);
}

.dashboard-1 .left .awssld,
.dashboard-1 .right .awssld {
  min-height: 60vh;
}

.dashboard-1 .title-3 {
  font-weight: 600 !important;
}

@media (max-width: 768px) {

  .dashboard-1.app-container {
    grid-template-rows: 1fr auto 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'main'
      'footer';
    background-size: cover;
    background-attachment: scroll;
  }

  .dashboard-1 header,
  .dashboard-1 footer {
    height: auto;
  }

  .dashboard-1 .left,
  .dashboard-1 .right {
    display: none;
    /* Hide sidebars on mobile */
  }

  .dashboard-1 main {
    height: auto;
    min-height: 80vh;
  }

  .dashboard-1 .list-container,
  .dashboard-1 .list-container-comments {
    width: 100%;
    /* Make list containers full width on mobile */
    height: auto;
    border-radius: 0;
  }

  .dashboard-1 .border-list {
    padding: 10px;
  }

  .dashboard-1 .big-text {
    font-size: 1.2rem;
    transform: scaleX(1);
  }

  .dashboard-1 h5 {
    font-size: 1.4rem;
  }

  .dashboard-1 li {
    font-size: 1.2rem;
  }

  .dashboard-1 .awssld {
    height: 40vh;
  }

  .dashboard-1 .awssld__timer {
    margin-top: 5px;
  }

  .dashboard-1 .title-2 {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 962px) and (max-width: 962px) and (min-height: 541px) and (max-height: 541px) {

  .dashboard-1 .left .title-2,
  .dashboard-1 .right .title-2 {
    margin: 2rem;
  }

  .dashboard-1 .left .awssld,
  .dashboard-1 .right .awssld {
    min-height: 57vh;
  }

}

@media (max-width: 965px) {

  html:has(.dashboard-1) {
    font-size: 7px;
    /* 1rem = 16px */
  }

  .dashboard-1 .list-group-item:first-child {
    margin-top: 0px;
  }

  .dashboard-1 .list-group-item {
    padding: 2px;
    min-height: 20px;
  }

  .dashboard-1 .sinagog-name {
    padding: 5px 7px;
  }

  .dashboard-1 .clock-container {
    padding-top: 0 !important;
    font-size: 4.2rem;
  }

  .border-list h6 {
    font-size: 1.3rem;
  }
}