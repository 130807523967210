@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
input {
    font-family: "Poppins", sans-serif;
}
p{
    padding: 0;
    margin: 0;
}
.login .container {
    position: relative;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;
    overflow: hidden;
    max-width: 1800px;
    margin: 0px;
}

.login .forms-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.login .signin-signup {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 75%;
    width: 50%;
    transition: 1s 0.7s ease-in-out;
    display: grid;
    grid-template-columns: 1fr;
    z-index: 5;
}

.login form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0rem 5rem;
    transition: all 0.2s 0.7s;
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.login form.sign-up-form {
    opacity: 0;
    z-index: 1;
}

.login form.sign-in-form {
    z-index: 2;
}

.login .title {
    font-size: 2.2rem;
    color: #444;
    margin-bottom: 10px;
}

.login .input-field {
    max-width: 380px;
    width: 100%;
    background-color: #f0f0f0;
    margin: 10px 0;
    height: 55px;
    border-radius: 55px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 0.4rem;
    position: relative;
    text-align: right;
    direction: rtl;
    align-items: center;
}

.login .input-field svg {
    text-align: center;
    line-height: 55px;
    color: #acacac;
    transition: 0.5s;
    font-size: 1.4rem;
    padding: 5px;
}

.login .input-field input {
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
    text-align: right;
}

.login .input-field input::placeholder {
    color: #aaa;
    font-weight: 500;
}

.login .social-text {
    padding: 0.7rem 0;
    font-size: 1rem;
}

.login .social-media {
    display: flex;
    justify-content: center;
}

.login .social-icon {
    height: 46px;
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.45rem;
    color: #333;
    border-radius: 50%;
    border: 1px solid #333;
    text-decoration: none;
    font-size: 1.1rem;
    transition: 0.3s;
}

.login .social-icon:hover {
    color: #4481eb;
    border-color: #4481eb;
}

.login .btn {
    width: 150px;
    background-color: #a7c7e7d9;
    border: none;
    outline: none;
    height: 49px;
    border-radius: 49px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px 0;
    cursor: pointer;
    transition: 0.5s;
    pointer-events: auto;
}

.login .btn:hover {
    background-color: #4d84e2;
}

.login .panels-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.login .container:before {
    content: "";
    position: absolute;
    height: 2000px;
    width: 2000px;
    top: -10%;
    right: 48%;
    transform: translateY(-50%);
    background: #a7c7e7d9;
    transition: 1.8s ease-in-out;
    border-radius: 50%;
    z-index: 6;
}

.login .image {
    width: 100%;
    transition: transform 1.1s ease-in-out;
    transition-delay: 0.4s;
}

.login .panel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    text-align: center;
    z-index: 6;
}

.login .left-panel {
    pointer-events: all;
    padding: 3rem 17% 2rem 12%;
}

.login .right-panel {
    pointer-events: none;
    padding: 3rem 12% 2rem 17%;
}

.login .panel .content {
    color: #000000;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
}

.login .panel h3 {
    font-weight: 600;
    line-height: 1;
    font-size: 1.5rem;
}

.login .panel p {
    font-size: 0.95rem;
    padding: 0.7rem 0;
}

.login .btn.transparent {
    margin: 0;
    background: none;
    border: 2px solid #000000;
    width: 130px;
    height: 41px;
    font-weight: 600;
    font-size: 0.8rem;
}

/* .right-panel .image,
.right-panel .content {
  transform: translateX(800px);
} */

/* ANIMATION */

.login .container.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 52%;
}

.login .container.sign-up-mode .left-panel .image,
.login .container.sign-up-mode .left-panel .content {
    transform: translateX(-800px);
}

.login .container.sign-up-mode .signin-signup {
    left: 25%;
}

.login .container.sign-up-mode form.sign-up-form {
    opacity: 1;
    z-index: 2;
}

.login .container.sign-up-mode form.sign-in-form {
    opacity: 0;
    z-index: 1;
}

.login .container.sign-up-mode .right-panel .image,
.login .container.sign-up-mode .right-panel .content {
    transform: translateX(0%);
}

.login .container.sign-up-mode .left-panel {
    pointer-events: none;
}

.login .container.sign-up-mode .right-panel {
    pointer-events: all;
}

.place {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: center;
}

.login .place .input-field {
    width: 50%;
}

.login .place .input-field input {
    padding-right: 15px;
}

@media (max-width: 870px) {
    .login .container {
        min-height: 800px;
        height: 100vh;
    }

    .login .signin-signup {
        width: 100%;
        top: 70%;
        transform: translate(-50%, -100%);
        transition: 1s 0.8s ease-in-out;
    }

    .login .signin-signup,
    .login .container.sign-up-mode .signin-signup {
        left: 50%;
    }

    .login .panels-container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr 1fr;
    }

    .login .panel {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 2.5rem 8%;
        grid-column: 1 / 2;
    }

    .login .right-panel {
        grid-row: 1 / 1;
    }

    .login .left-panel {
        grid-row: 1 / 2;
    }

    .login .image {
        width: 300px;
        transition: transform 0.9s ease-in-out;
        transition-delay: 0.6s;
    }

    .login .panel .content {
        padding-right: 15%;
        transition: transform 0.9s ease-in-out;
        transition-delay: 0.8s;
    }

    .login .panel h3 {
        font-size: 1.2rem;
    }

    .login .panel p {
        font-size: 0.7rem;
        padding: 0.5rem 0;
    }

    .login .btn.transparent {
        width: 110px;
        height: 35px;
        font-size: 0.7rem;
    }

    .login .container:before {
        width: 1500px;
        height: 1500px;
        transform: translateX(-50%);
        left: 30%;
        bottom: 68%;
        right: initial;
        top: initial;
        transition: 2s ease-in-out;
    }

    .login .container.sign-up-mode:before {
        transform: translate(-50%, 100%);
        bottom: 32%;
        right: initial;
    }

    .login .container.sign-up-mode .left-panel .image,
    .login .container.sign-up-mode .left-panel .content {
        transform: translateY(-300px);
    }

    .login .container.sign-up-mode .right-panel .image,
    .login .container.sign-up-mode .right-panel .content {
        transform: translateY(0px);
    }

    .login .right-panel .image,
    .login .right-panel .content {
        transform: translateY(0px);
    }

    .login .container.sign-up-mode .signin-signup {
        top: 5%;
        transform: translate(-50%, 0);
    }
}

@media (max-width: 570px) {
    .login form {
        padding: 0 1.5rem;
    }

    .login .image {
        display: none;
    }

    .login .panel .content {
        padding: 0.5rem 1rem;
    }

    .login .container {
        padding: 1.5rem;
    }

    .login .container:before {
        bottom: 72%;
        left: 50%;
    }

    .login .container.sign-up-mode:before {
        bottom: 15%;
        left: 50%;
    }
    .place {
        width:85%;
    }
}