@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap');

body {
  margin: 0;
  font-family: Assistant, BlinkMacSystemFont, 'Segoe UI' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}
/* body:has(.mgm-page) */
body{
  background: rgb(211 220 225 / 16%);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.reactJewishDatePicker .navigation{
  padding-bottom: 25px;
}
@media (max-width: 768px) {
  .reactJewishDatePicker .monthWrapper {
    left: 0;
  }
}

.swal-cancel-button {
  background-color: #7066e0 !important; /* צבע אדום לכפתור הביטול */
  color: white !important;
  font-weight: bold !important;
  border: none !important;
}

.swal-confirm-button {
  background-color: #6e7881 !important; /* כפתור אפור פחות בולט */
  color: white !important;
  font-weight: normal !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  border: none !important;
}


/* Modern Glowing SweetAlert2 Style */
.swal2-popup {
    font-family: 'Heebo', sans-serif;
    border-radius: 20px;
    padding: 1.5rem;
    background: #ffffff;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
                0 5px 15px rgba(0, 0, 0, 0.07);
    border: 1px solid rgba(226, 232, 240, 0.8);
}
h2#swal2-title {
  font-weight: 600;
}
.swal2-text {
    color: #4a5568;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 2rem;
}

.swal2-icon {
    border: 4px solid #667eea;
    margin: 2rem auto;
    transform: scale(1.2);
}

.swal2-icon.swal2-warning {
    color: #667eea;
    border-color: #667eea;
    filter: drop-shadow(0 0 10px rgba(102, 126, 234, 0.3));
}

/* Glowing button styles */
.swal-confirm-button {
    background:linear-gradient(135deg, #f6584d, red) !important;
    color: white !important;
    border-radius: 12px !important;
    font-weight: 600 !important;
    border: none !important;
    transition: all 0.4scubic-bezier(0.4, 0, 0.2, 1) !important;
    box-shadow: 0 5px 15px rgba(246, 88, 77, 0.3) !important;
}

.swal-confirm-button:hover {
    transform: translateY(-3px) scale(1.02);
    box-shadow: 0 8px 20px rgba(246, 88, 77, 0.4) !important;
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm){
  background: linear-gradient(135deg, #2ecc71, #27ae60) !important;
  color: white !important;
  border-radius: 12px !important;
  border: none !important;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) !important;
  box-shadow: 0 5px 15px rgba(160, 174, 192, 0.3) !important;
}
div:where(.swal2-container) h2:where(.swal2-title){
  font-size: 1.4em !important;
}
.swal-cancel-button,
div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-cancel) {
    background: linear-gradient(135deg, #a0aec0, #718096) !important;
    color: white !important;
    border-radius: 12px !important;
    font-weight: 600 !important;
    margin-left: 1rem !important;
    border: none !important;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) !important;
    box-shadow: 0 5px 15px rgba(160, 174, 192, 0.3) !important;
}

.swal-cancel-button:hover {
    transform: translateY(-3px) scale(1.02);
    box-shadow: 0 8px 20px rgba(160, 174, 192, 0.4) !important;
}

/* RTL adjustments with modern spacing */
.swal2-popup.swal2-rtl {
    direction: rtl;
    text-align: right;
}

/* Enhanced animation */
.swal2-popup {
    animation: modernPopup 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes modernPopup {
    from {
        opacity: 0;
        transform: scale(0.8) translateY(20px);
    }
    to {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

/* Fancy overlay with blur effect */
.swal2-backdrop-show {
    background: rgba(26, 32, 44, 0.7) !important;
    backdrop-filter: blur(5px);
}

/* Hover effect for the entire popup */
.swal2-popup:hover {
    transform: translateY(-2px);
    box-shadow: 0 20px 40px rgba(50, 50, 93, 0.12),
                0 8px 20px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
}

/* Custom scrollbar for longer content */
.swal2-popup::-webkit-scrollbar {
    width: 8px;
}

.swal2-popup::-webkit-scrollbar-track {
    background: #f7fafc;
    border-radius: 10px;
}

.swal2-popup::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 10px;
}

.swal2-popup::-webkit-scrollbar-thumb:hover {
    background: #a0aec0;
}

